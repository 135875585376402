import React from "react";
import MethodologyImage from "../../assets/images/methodology.png";

const Methodology = () => {
  return (
    <>
      <div className="custom-container">
        <h3 className="title_text">OUR TRAINING METHODOLOGY</h3>
        <p>
          At{" "}
          <span style={{ color: "var(--secondary-color)", fontWeight: "bold" }}>
            INNOCAL SOLUTION
          </span>{" "}
          we believe in holistic development of our students and create
          meaningful academic programmes in specialized career fields. We set
          the stage for their higher education and career pathways. Teaching
          methodology comprises of classroom sessions, master classes, case
          studies, capstone projects, practical training, group work and
          discussions. The approach is to have the right balance of theory,
          practice and experience. The "real world relevance" is vital
          throughout the programme.
        </p>
        <div className="row align-items-center">
          <div className="col-sm d-flex justify-content-center">
            <img
              style={{ margin: ".5rem" }}
              className="img-fluid"
              src={MethodologyImage}
              alt=""
              height={300}
              width={300}
            />
          </div>

          <p style={{ margin: ".5rem" }} className="col-sm">
            Application Our teaching methodology is focused on Knowledge,
            Application and enhancing skills. These are the prerequisites for a
            successful corporate career. Our modern and enhanced curriculum has
            a blend of immersive industry exposure through the Practical
            Trainings &Internships, intensive personal and professional
            development through the classroom sessions and workshops, combined
            with CSR activities, all come together to create an experiential
            learning process unlike any in the industry.
          </p>
        </div>
      </div>
      <div className="custom-container">
        <h3 className="title_text">ASSESSMENTS</h3>
        <ul>
          <li>
            In line with the{" "}
            <span
              style={{ color: "var(--secondary-color)", fontWeight: "bold" }}
            >
              INNOCAL SOLUTION
            </span>{" "}
            commitment to practice-based education, a large part of the
            assessment will relate to the demonstration of the student's ability
            to synthesize class room learning with real life scenarios of
            managing and running business.
          </li>
          <li>
            Continuous Assessment process will judge the depth to which the
            student understands the theory and have developed critical thinking
            skills which help them evaluate the relevance of what they have
            learned.
          </li>
          <li>
            Students will encounter many different kinds of assessment methods,
            for example: written exams, written assignments, portfolios, project
            work, reports, individual or group oral presentations and practical
            skills assessment. All of which will enable them to gain valuable
            experience to use within the workplace.
          </li>
          <li>
            Students will be expected to complete one assessment per unit.
          </li>
        </ul>
      </div>
      <div className="custom-container">
        <h3 className="title_text">CERTIFICATIONS</h3>
        <p>
          The{" "}
          <span style={{ color: "var(--secondary-color)", fontWeight: "bold" }}>
            INNOCAL SOLUTION
          </span>{" "}
          Certifications are coveted qualifications which recognize outstanding
          students by substantiating their conduct and professionalism to
          prospective employers.
        </p>
      </div>
    </>
  );
};

export default Methodology;
