import React from "react";
import CoursesImage from "../../assets/images/courses1.jpg";
import CoursesIcon from "../../assets/images/course_icon.png";
import CoursesImageTwo from "../../assets/images/header4.jpg";

const Courses = () => {
  const certificateCoursesList = [
    "Certificate Course ni Practical Taxation - GST,TDS, PF, PT,TI",
    "International Financial Reporting Standards (IFRS)",
    "Tally",
    "Certificate Course in Banking Fundamentals",
    "Certificate Course in Anti Money Laundering, Know our Customer and Payment Banks",
    "Digital Marketing",
    "Music Production",
    "Certificate Course in Cyber Law",
    "RESEARCH ANALYST - 1",
    "UNDERSTANDING OF PERSONAL FINANCE",
    "Financial Statement Analysis",
    "Applied Cloud Computing",
    "Big Data on Cloud",
    "Course on Advance Ms - Excel",
    "Al in Real World Applications",
    "Bot Development",
    "Photoshop",
    "PowerPoint",
    "Data Analytics using Excel",
    "2D &3D Animation",
  ];

  const integratedCoursesList = [
    "CA Foundation Course",
    "CA Inter Course",
    "CS Foundation",
    "CS Inter",
    "LLB entrance",
    "CFA Level- 1",
  ];

  return (
    <div>
      <div
        // style={{ boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)" }}
        className="custom-container"
      >
        <div className="position-relative">
          <img className="width-100" src={CoursesImage} alt="" height={150} />
          <h3 className="title_text position-absolute course-title-text">
            LIST OF CERTIFICATE COURSES
          </h3>
          <div
            style={{ margin: "2rem 0" }}
            className="d-flex flex-wrap justify-content-center"
          >
            {certificateCoursesList.map((item) => (
              <div className="course_card">
                <img
                  src={CoursesIcon}
                  alt=""
                  width={50}
                  height={50}
                  style={{ marginBottom: "10px" }}
                />
                <h6>{item}</h6>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/*  */}
      <div
        // style={{ boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)" }}
        className="custom-container"
      >
        <div className="position-relative">
          <img
            style={{ objectFit: "cover" }}
            className="width-100"
            src={CoursesImageTwo}
            alt=""
            height={150}
          />
          <h3
            // style={{
            //   top: "20%",
            //   right: "20px",
            //   color: "var(--secondary-color)",
            // }}
            className="title_text position-absolute course-title-text2"
          >
            LIST OF INTEGRATED COURSES
          </h3>
          <div
            style={{ margin: "2rem 0" }}
            className="d-flex flex-wrap justify-content-center"
          >
            {integratedCoursesList.map((item) => (
              <div className="course_card">
                <img
                  src={CoursesIcon}
                  alt=""
                  width={50}
                  height={50}
                  style={{ marginBottom: "10px" }}
                />
                <h6>{item}</h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
