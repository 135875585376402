import React from "react";
import Logo from "../../assets/images/logo.png";
import YT from "../../assets/images/social_media/yt.svg";
import FB from "../../assets/images/social_media/fb.svg";
import IG from "../../assets/images/social_media/ig.svg";
import IN from "../../assets/images/social_media/in.svg";

const Footer = () => {
  return (
    <div id="footer" className="footer">
      <div className="">
        <div className="row">
          <div className="col-sm">
            <img src={Logo} alt="" width={30} />
            <h3>Innocal</h3>
            <p className="footer-text">Lorem Ipsum is simply dummy text of.</p>
          </div>
          <div className="col-sm">
            <h6>CONTACT US</h6>
            <p className="footer-text">+91- 9930272045</p>
            <p className="footer-text">amriteshO@qmail.com</p>
          </div>
          {/* <div>
            <h6>COMPANY</h6>
            <p>About Us</p>
            <p>Our Vision</p>
            <p>Our Values</p>
          </div> */}
          <div className="col-sm">
            <h6>LEGAL</h6>
            <p className="footer-text">Terms & Conditions</p>
            <p className="footer-text">Privacy Policy</p>
          </div>
        </div>
        <div
          style={{
            height: "1px",
            background: "rgb(232 232 232)",
            margin: ".8rem .5rem",
          }}
        ></div>
        <div className="d-flex justify-content-between">
          <div style={{ color: "#282828a6",fontSize:"13px" }}>
            2024 Innocal Solutions All Rights Reserved
          </div>
          <div className="d-flex justify-content-between">
            <img
              style={{ margin: "0 10px", borderRadius: "30%" }}
              width={25}
              src={IN}
              alt=""
            />
            <img
              style={{ margin: "0 10px", borderRadius: "30%" }}
              width={25}
              src={YT}
              alt=""
            />
            <img
              style={{ margin: "0 10px", borderRadius: "30%" }}
              width={25}
              src={IG}
              alt=""
            />
            <img
              style={{ margin: "0 10px", borderRadius: "30%" }}
              width={25}
              src={FB}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
