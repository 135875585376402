import React from "react";
import EthicalImage from "../../assets/images/values_icon/ethical.png";
import ServiceImage from "../../assets/images/values_icon/service.png";
import MutualImage from "../../assets/images/values_icon/mutual.png";
import RecognitionImage from "../../assets/images/values_icon/recognition.png";
import PassionImage from "../../assets/images/values_icon/passion.png";
import ResearchImage from "../../assets/images/values_icon/research.png";

const OurValues = () => {

  return (
    <div
    id="our-values"
      className="text-center custom-container"
    >
      <div>
        <h3 className="title_text">Our Core Values</h3>
        <p>
          These values are our core competencies and aid us in fostering a
          collaborative working and learning environment.
        </p>
      </div>
      <div
        style={{ marginTop: "2rem" }}
        className="d-flex flex-wrap justify-content-center"
      >
        <div className="values_card">
          <img src={EthicalImage} alt="" width={80} />
          <h6 style={{ marginTop: "10px" }} className="title_text">
            High ethical and moral standards
          </h6>
        </div>
        <div className="values_card">
          <img src={ServiceImage} alt="" width={80} />
          <h6 style={{ marginTop: "10px" }} className="title_text">
            Service &respect towards the community &industry
          </h6>
        </div>
        <div className="values_card">
          <img src={MutualImage} alt="" width={80} />
          <h6 style={{ marginTop: "10px" }} className="title_text">
            Mutual trust and teamwork
          </h6>
        </div>
        <div className="values_card">
          <img src={RecognitionImage} alt="" width={80} />
          <h6 style={{ marginTop: "10px" }} className="title_text">
            Recognition of efforts and achievements
          </h6>
        </div>
        <div className="values_card">
          <img src={PassionImage} alt="" width={80} />
          <h6 style={{ marginTop: "10px" }} className="title_text">
            Passion for excellence
          </h6>
        </div>
        <div className="values_card">
          <img src={ResearchImage} alt="" width={80} />
          <h6 style={{ marginTop: "10px" }} className="title_text">
            Research and Innovation as a way of life
          </h6>
        </div>
      </div>
    </div>
  );
};

export default OurValues;
