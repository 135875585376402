import React from "react";
import HeaderImage from "../../assets/images/header3.jpg";
import Logo from "../../assets/images/logo.png";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { ReactTyped } from "react-typed";

const Header = () => {
  return (
    <>
      <div className="position-relative">
        <div style={{ zIndex: "1" }} className="position-relative width-100 ">
          <Navbar
            style={{ padding: ".5rem" }}
            expand="lg"
            className="bg-body-tertiary"
          >
            <Container fluid>
              <Navbar.Brand style={{ fontWeight: "600" }} href="#">
                <img src={Logo} alt="" width={17} /> Innocal
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto my-2 my-lg-0"
                  style={{ maxHeight: "100px", marginLeft: "4rem" }}
                  navbarScroll
                >
                  <Nav.Link href="#about-us">About Us</Nav.Link>
                  <Nav.Link href="#our-vision">Our Vision</Nav.Link>
                  <Nav.Link href="#our-values">Our Values</Nav.Link>
                </Nav>
                <Form className="d-flex">
                  <button
                    style={{
                      backgroundColor: "var(--secondary-color)",
                      border: "none",
                      color: "black",
                    }}
                    className="btn"
                  >
                    <a
                      style={{ textDecoration: "none", color: "black" }}
                      href="#footer"
                    >
                      Contact Us
                    </a>
                  </button>
                </Form>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          {" "}
          <div
            style={{
              zIndex: "1",
              textAlign: "center",
              color: "white",
              padding: "2rem",
            }}
            className="position-absolute"
          >
            {" "}
            <h1 className="header-title-text" style={{ fontWeight: "bold" }}>
              WHEN PASSION <br></br>
              <span>MEETS PROFESSION</span>
            </h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
            <button
              style={{
                backgroundColor: "var(--secondary-color)",
                border: "none",
                marginTop: "2rem",
              }}
              className="btn display-none-mobile"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="#footer"
              >
                Get in touch
              </a>
            </button>
          </div>
          <div className="position-relative">
            <img src={HeaderImage} alt="" className="width-100 img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
