import React from "react";

const TopReasons = () => {
  const reasonsList = [
    {
      title: "",
      subTitle: "",
    },
  ];
  return (
    <div className="custom-container">
      <h3 className="title_text">
        <span style={{ color: "var(--secondary-color)", fontWeight: "800" }}>
          TOP 10
        </span>{" "}
        REASONS TO CHOOSE INNOCAL SOLUTIONS
      </h3>
      <p className="subtitle_text">Why we are different?</p>
      <div className="d-flex  flex-wrap justify-content-center align-items-start mt-5">
        <div style={{ margin: "5px 1rem" }} className="col-sm">
          <div className="d-flex justify-content-center align-items-center">
            <span className="number_text">1.</span>
            <div>
              <div style={{ fontSize: "1.3rem" }} className="title_text">
                It encourages students to think about others.
              </div>
              <p>
                The service-learning course allows students to make a positive
                difference to the world around them.
              </p>
            </div>
          </div>
        </div>
        <div style={{ margin: "5px 1rem" }} className="col-sm">
          <div className="d-flex justify-content-center align-items-center">
            <span className="number_text">2.</span>
            <div>
              <div style={{ fontSize: "1.3rem" }} className="title_text">
                Explore, understand & engage in real-world issues
              </div>
              <p>
                The CP requires students to explore, analyze and evaluate 3
                global issues from a local perspective.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex  flex-wrap justify-content-center align-items-start mt-5">
        <div style={{ margin: "5px 1rem" }} className="col-sm">
          <div className="d-flex justify-content-center align-items-center">
            <span className="number_text">3.</span>
            <div>
              <div style={{ fontSize: "1.3rem" }} className="title_text">
                It integrates academic & practical learning.
              </div>
              <p>
                The CP combines academic rigor with practical study: & develops
                skills &competencies required for lifelong learning.
              </p>
            </div>
          </div>
        </div>
        <div style={{ margin: "5px 1rem" }} className="col-sm">
          <div className="d-flex justify-content-center align-items-center">
            <span className="number_text">4.</span>
            <div>
              <div style={{ fontSize: "1.3rem" }} className="title_text">
                It allows students to do what they really want to do.
              </div>
              <p>
                Students can combine the academic subjects with their interests
                & skills
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex  flex-wrap justify-content-center align-items-start mt-5">
        <div style={{ margin: "5px 1rem" }} className="col-sm">
          <div className="d-flex justify-content-center align-items-center">
            <span className="number_text">5.</span>
            <div>
              <div style={{ fontSize: "1.3rem" }} className="title_text">
                It gives students more career options.
              </div>
              <p>
                With a CP, students can access further education,
                apprenticeships & employmentin areas such as business,IT, health
                care, sports & many others
              </p>
            </div>
          </div>
        </div>
        <div style={{ margin: "5px 1rem" }} className="col-sm">
          <div className="d-flex justify-content-center align-items-center">
            <span className="number_text">6.</span>
            <div>
              <div style={{ fontSize: "1.3rem" }} className="title_text">
                Learn about different cultures.
              </div>
              <p>
                Language development encourages students to engage with other
                cultures & increase their understanding of the wider world.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex  flex-wrap justify-content-center align-items-start mt-5">
        <div style={{ margin: "5px 1rem" }} className="col-sm">
          <div className="d-flex justify-content-center align-items-center">
            <span className="number_text">7.</span>
            <div>
              <div style={{ fontSize: "1.3rem" }} className="title_text">
                Build friendships & connections
              </div>
              <p>
                Collaborative projects allow students to build friendships as
                well as working relationships.
              </p>
            </div>
          </div>
        </div>
        <div style={{ margin: "5px 1rem" }} className="col-sm">
          <div className="d-flex justify-content-center align-items-center">
            <span className="number_text">8.</span>
            <div>
              <div style={{ fontSize: "1.3rem" }} className="title_text">
                It encourages students to apply their creativity in innovative
                ways.
              </div>
              <p>
                Personal and professional skills courses promote creative
                thinking techniques.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex  flex-wrap justify-content-center align-items-start mt-5">
        <div style={{ margin: "5px 1rem" }} className="col-sm">
          <div className="d-flex justify-content-center align-items-center">
            <span className="number_text">9.</span>
            <div>
              <div style={{ fontSize: "1.3rem" }} className="title_text">
                Develop essential life skills.
              </div>
              <p>
                Skills including critical thinking, communication &personal
                development is an important focus of the CP.
              </p>
            </div>
          </div>
        </div>
        <div style={{ margin: "5px 1rem" }} className="col-sm">
          <div className="d-flex justify-content-center align-items-center">
            <span className="number_text">10.</span>
            <div>
              <div style={{ fontSize: "1.3rem" }} className="title_text">
                Become an independent learner
              </div>
              <p>
                Taking part in the reflective project, students learn how to
                research subjects &explore multiple sources of Information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopReasons;
