import React from "react";
import AboutUsImage from "../../assets/images/about.jpg";
import VisionImage from "../../assets/images/vision.jpg";

const AboutUs = () => {
  return (
    <>
      <div id="about-us" className="custom-container">
        <div className="row">
          <div className="col-sm">
            <img
              className="img-fluid"
              src={AboutUsImage}
              alt="About Us"
              width={600}
            />
          </div>
          <div className="col-sm">
            <h3 className="title_text">About Us</h3>
            <p>
              Education industry across the globe has seen a major paradigm
              shift. The advent of specialized fields in various sectors over
              the last decade has necessitated the need for specialized academic
              courses to prepare the next generation of entrepreneurs and
              Managers.
            </p>
            <p>
              Education industry across the globe has seen a major paradigm
              shift. The advent of specialized fields in various sectors over
              the last decade has necessitated the need for specialized academic
              courses to prepare the next generation of entrepreneurs and
              Managers. Innocal solutions is an educational research
              organization which offer related courses in multiple academic
              streams related to new age careers. The leadership at Innocal
              solution are industry veterans and prominent academicians who
              understand the confluence of content and its relevance to future
              future careers. INNocal SOLUTIONS is engaged in developing
              career-oriented courses for the Educational industry in
              collaboration with various College &Universities. tI is a first of
              its's kind initiative and shares synergy with the ideology of
              practical world.
            </p>
            <p>
              Innocal solutions commands a robust network of corporate and
              prominent universities across the world, thereby offering
              unmatched practical training, internships and higher education
              pathways to its students. We are passionate about offering the
              right blend of practical knowledge and experience, creating a
              truly futuristic curriculum aimed at producing highly skilled
              individuals and a launch pad for entrepreneurs.
            </p>
          </div>
        </div>
      </div>
      <div id="our-vision" className="custom-container">
        <div className="row d-flex align-items-center">
          <div className="col-sm">
            <h3 className="title_text">Our Vision</h3>
            <p>
              Innocal solutions aims to deliver highest standards of
              professional education in College &schools by certifying students
              in new age career fields. We are committed to educating students
              to be thoughtful, imaginative individuals, ready to live, adapt,
              and lead in an increasingly competitive world.
            </p>
          </div>
          <div className="col-sm">
            <img className="img-fluid" src={VisionImage} alt="About Us" width={500} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
