import logo from "./logo.svg";
import "./App.css";
import Header from "./screens/home_page/Header.tsx";
import AboutUs from "./screens/home_page/AboutUs.tsx";
import OurValues from "./screens/home_page/OurValues.tsx";
import Courses from "./screens/home_page/Courses.tsx";
import Methodology from "./screens/home_page/Methodology.tsx";
import TopReasons from "./screens/home_page/TopReasons.tsx";
import Footer from "./screens/home_page/Footer.tsx";

function App() {
  return (
    <>
      <Header />
      <AboutUs />
      <OurValues />
      <Courses />
      <TopReasons />
      <Methodology />
      <Footer/>
    </>
    // <div style={{ background: "var(--secondary-color)" }} className="">
    //   <h1 style={{color:"white"}}>
    //     WHEN PASSION <br></br>
    //     <ReactTyped
    //       style={{ color: "var(--primary-color)" }}
    //       strings={["MEETS PROFESSION"]}
    //       typeSpeed={300}
    //       backSpeed={20}
    //       loop
    //     />
    //   </h1>
    // </div>
  );
}

export default App;
